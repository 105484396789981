import React from 'react'

const Footer = () => {
  return (
    <>
      <footer
        id="footer"
        className="footer-1 hideScroll"
        style={{ backgroundColor: '#1D1D1D', overflow: 'scroll' }}
      >
        <div className="main-footer widgets-dark typo-light" style={{ padding: '5rem' }}>
          <div className="container">
            <div className="row ">
              <div className="col-xs-12  col-sm-8 col-md-3">
                <div className="widget subscribe no-box">
                  <img
                    src={process.env.PUBLIC_URL + '/Media/Group 33.png'}
                    alt="Logo"
                    className="mb-3 img-fluid"
                  />
                  {/* <!-- Added "mb-3" for margin-bottom --> */}
                  <p className="mt-2 w-100" style={{ color: '#FFFFFFDE' }}>
                    There are many variations of pass ages of Lorem Ipsum available, but the
                    majority have suffered alteration in some form working insurigo
                  </p>
                </div>
                <div style={{ color: '#FFFFFFDE' }}>
                  <div className="row">
                    <div className="col">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <i className="bi bi-facebook"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="bi bi-twitter-x"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="bi bi-whatsapp"></i>
                        </li>
                        <li className="list-inline-item">
                          <i className="bi bi-google"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12   col-sm-8 col-md-3">
                <div className="widget no-box">
                  <h5 className="widget-title mb-3" style={{ color: 'white' }}>
                    Quick Links<span></span>
                  </h5>
                  <ul className="thumbnail-widget list-unstyled">
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          About Us
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Our Pricing
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Our Mission
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Latest News
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Contact Us
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12  col-sm-8 col-md-3">
                <div className="widget no-box">
                  <h5 className="widget-title mb-3" style={{ color: 'white' }}>
                    Our Services<span></span>
                  </h5>
                  <ul className="thumbnail-widget list-unstyled">
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Travel Insurance
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Life Insurance
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          House Insurance
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Car Insurance
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Family Insurance
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xs-12  col-sm-8 col-md-3">
                <div className="widget no-box">
                  <h5 className="widget-title mb-3" style={{ color: 'white' }}>
                    Get In Touch<span></span>
                  </h5>
                  <ul className="thumbnail-widget list-unstyled">
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Gulberg III, Lahore, Pakistan
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          (+880)155-69569
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          madadgaaragent@gmail.com
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          More Information
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="thumb-content">
                        <a className="text-decoration-none" style={{ color: '#FFFFFFDE' }}>
                          Family Insurance
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container footer-copyright" style={{ backgroundColor: '#FFFFFF0F' }}>
          <div>
            <div className="row small">
              <div
                className="col-md-12 d-flex flex-wrap justify-content-center pt-2"
                style={{ color: '#FFFFFFDE' }}
              >
                <div className="col-md-6">
                  <p>© 2024 Madadgaar Expert Partner. Designed By My DIgital Pixels</p>
                </div>
                <div className="d-flex flex-wrap justify-content-center">
                  <p className="mr-2">Sitemap</p>
                  <p className="mr-2">|</p>
                  <p className="mr-2">Privacy Policy</p>
                  <p className="mr-2">|</p>
                  <p className="mr-2">Terms of use</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
