import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import ButtonWithLoader from '../ButtonWithLoader'

const Modal = ({ open, loading, onClose, onSubmit, children, edit, title = 'Modal' }) => {
  return (
    <CModal className="w-full" visible={open}>
      <CModalHeader>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{children}</CModalBody>
      <CModalFooter>
        <CButton onClick={onClose} color="secondary">
          Close
        </CButton>
        <ButtonWithLoader onClick={onSubmit} color={'danger'} loading={loading}>
          {edit ? 'Edit' : 'Post'}
        </ButtonWithLoader>
      </CModalFooter>
    </CModal>
  )
}

export default Modal
