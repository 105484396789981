import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CForm, CRow } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordOtpVerification, SignIn, VerifyUser } from '../../../store/action'
import OtpInput from 'react-otp-input'

import toast from 'react-hot-toast'

import ButtonWithLoader from 'src/components/ButtonWithLoader'
const VerifyUserOtp = () => {
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const location = useLocation()

  const user = useSelector((state) => state.auth.authDetails)
  const authLoading = useSelector((state) => state.auth.authLoading)
  const forgotPasswordstate = useSelector((state) => state.auth.forgotPassword)
  const dispatch = useDispatch()
  console.log('forgotPasswordstate', forgotPasswordstate)

  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [user])

  const handleSubmit = async () => {
    try {
      const res = await dispatch(
        VerifyUser({ otp, email: location.search.trim().split('?email=')[1] }, navigate),
      )
    } catch (error) {
      toast.error('something went wrong')
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody className="d-flex flex-column justify-content-center">
                  <h3>Verify otp</h3>
                  <CForm>
                    <OtpInput
                      className="form-control"
                      inputStyle={{
                        padding: '10px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        width: '100px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                        outline: 'none',
                        transition: 'border 0.3s',
                      }}
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </CForm>
                  <div className="d-flex justify-content-end">
                    <ButtonWithLoader
                      loading={authLoading}
                      onClick={() => handleSubmit()}
                      color="primary"
                      className="px-4"
                    >
                      Verify
                    </ButtonWithLoader>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default VerifyUserOtp
