import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPasswordResetPassword, SignIn } from '../../../store/action'

import toast from 'react-hot-toast'
import hide from '../../../assets/icons/hide.svg'
import show from '../../../assets/icons/show.svg'
import ButtonWithLoader from 'src/components/ButtonWithLoader'
import { checkPasswordStrength } from 'src/utils/hooks'
const ResetPassword = () => {
  const [loginDetail, setLoginDetails] = useState({ password: '', confirmPassword: '' })
  const navigate = useNavigate()
  const forgotPasswordstate = useSelector((state) => state.auth.forgotPassword)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('')
  const [isCorrectPassword, setIsCorrectPassword] = useState(false)
  const user = useSelector((state) => state.auth.authDetails)
  const authLoading = useSelector((state) => state.auth.authLoading)
  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (user) {
      navigate('/dashboard')
    }
  }, [user])
  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }
  const handleInputValue = (e) => {
    const { name, value } = e.target
    if (name === 'password') {
      let res = checkPasswordStrength(value, setPasswordErrorMessage)
      if (res) {
        setLoginDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          }
        })
        setIsCorrectPassword(res)
      } else {
        setIsCorrectPassword(res)
        setLoginDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          }
        })
      }
    } else {
      setLoginDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        }
      })
    }
  }
  const handleSubmit = async () => {
    try {
      if (loginDetail && loginDetail.password === loginDetail.confirmPassword) {
        if (forgotPasswordstate && forgotPasswordstate.email && forgotPasswordstate.otp) {
          if (isCorrectPassword) {
            const res = dispatch(
              ForgotPasswordResetPassword(
                { ...forgotPasswordstate, password: loginDetail.password },
                navigate,
                setLoading,
              ),
            )
          } else {
            toast.error('Please enter the strong password')
          }
        }
      } else {
        toast.error('Please enter the valid password')
      }
    } catch (error) {
      alert(error)
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h3>Reset Password</h3>
                    <div className="position-relative my-1">
                      <CFormInput
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        label="Password"
                        value={loginDetail.password}
                        onChange={handleInputValue}
                        aria-describedby="exampleFormControlInputHelpInline"
                      />
                      {showPassword ? (
                        <img
                          src={hide}
                          alt="hide"
                          style={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '40px',
                            right: '2px',
                            margin: '0 auto',
                          }}
                          onClick={handleTogglePassword}
                        />
                      ) : (
                        <img
                          src={show}
                          alt="show"
                          style={{
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '40px',
                            right: '2px',
                            margin: ' 0 auto',
                          }}
                          onClick={handleTogglePassword}
                        />
                      )}
                      <p className={`${isCorrectPassword ? 'text-success' : 'text-danger'}`}>
                        {passwordErrorMessage}
                      </p>
                    </div>
                    <div className="position-relative my-1">
                      <CFormInput
                        type={showPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        name="confirmPassword"
                        label="Confirm Password"
                        value={loginDetail.confirmPassword}
                        onChange={handleInputValue}
                        aria-describedby="exampleFormControlInputHelpInline"
                      />
                      {showPassword ? (
                        <img
                          src={hide}
                          alt="hide"
                          style={{
                            width: '18px',
                            height: '18px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '40px',
                            right: '2px',
                            margin: '0 auto',
                          }}
                          onClick={handleTogglePassword}
                        />
                      ) : (
                        <img
                          src={show}
                          alt="show"
                          style={{
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '40px',
                            right: '2px',
                            margin: ' 0 auto',
                          }}
                          onClick={handleTogglePassword}
                        />
                      )}
                    </div>
                    <div className="d-flex justify-content-end my-3">
                      <ButtonWithLoader
                        loading={loading}
                        onClick={() => handleSubmit()}
                        color="primary"
                        className="px-4"
                      >
                        Reset
                      </ButtonWithLoader>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
