import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { proCard } from 'src/utils/constants'

const PropertiesCard = () => {
  const { filterPublicPropertyLoading, filterPublicProperty } = useSelector((state) => state.common)
  const navigate = useNavigate()
  console.log('filterPublicProperty', filterPublicProperty)
  const handleNavigation = (data) => {
    navigate('/visitproperty', { state: data })
  }
  return (
    <div>
      {filterPublicPropertyLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : filterPublicProperty.length > 0 ? (
        <div className="container d-flex flex-column align-items-center flex-grow-1 mb-4 d-none d-md-flex">
          <div className="mb-5">
            <h2
              className="text-center"
              style={{
                fontfamily: 'Poppins',
                fontsize: '36px',
                fontweight: '500',
                color: '#1E1E1E',
              }}
            >
              AVAILABLE PROPERTIES
            </h2>
          </div>
          <div className="container">
            <div className="row">
              {filterPublicProperty.map((proCard, index) => (
                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 " key={index} style={{}}>
                  <div className="card  mx-auto" style={{ width: '100%' }}>
                    <img
                      src={
                        proCard?.projectImages && proCard?.projectImages.length > 0
                          ? proCard?.projectImages[0]
                          : process.env.PUBLIC_URL + '/Media/pro 1.png'
                      }
                      className="card-img-top"
                      alt="Card Image"
                      style={{ maxHeight: '200px', objectFit: 'cover' }}
                    />
                    <div className="card-body">
                      <h6
                        className="card-title"
                        style={{
                          fontfamily: 'Poppins',
                          fontsize: '15px',
                          fontweight: '400',
                          color: '#4D4D4F',
                        }}
                      >
                        {`${proCard?.title?.slice(0, 30)}...`}
                      </h6>
                      <p
                        className="card-text small"
                        style={{
                          fontfamily: 'Raleway',
                          fontsize: '14px',
                          fontweight: '500',
                          color: '#54595F',
                        }}
                      >
                        <img
                          src={process.env.PUBLIC_URL + '/Header Icons/Path-8200-1.jpg.png'}
                          className="mr-2"
                          alt="Icon"
                        />
                        {`${proCard?.propertyLocation?.slice(0, 30)}...`} <br />
                        <img
                          src={process.env.PUBLIC_URL + '/Header Icons/Symbol.png'}
                          className="mr-2"
                          alt="Icon"
                        />
                        {proCard?.propertyCity}
                      </p>

                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <p className="" style={{ fontSize: '13px' }}>
                            Booking from:
                            <span>
                              <h6 style={{ color: '#B7242A' }}>{proCard?.price}</h6>
                            </span>
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="" style={{ fontSize: '13px' }}>
                            Instalment from:
                            <span>
                              <h6 style={{ color: '#B7242A' }}>{proCard?.price}</h6>
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between flex-wrap">
                        <button
                          type="button"
                          className="btn"
                          style={{ borderColor: '#B7242A', color: '#B7242A' }}
                          onClick={() => handleNavigation(proCard)}
                        >
                          <span className="small">View Project</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center">No records found</div>
      )}
    </div>
  )
}

export default PropertiesCard
