import {
  APPROVED_WITHDRAW,
  BLOGS,
  BLOGS_ERROR,
  BLOGS_LOADING,
  COMMONFORM,
  COMMONFORM_ERROR,
  COMMONFORM_LOADING,
  FILTER_PROPERTY,
  FILTER_PROPERTY_ERROR,
  FILTER_PROPERTY_LOADING,
  INSTALLMENT_POSTS,
  INSTALLMENT_POSTS_ERROR,
  INSTALLMENT_POSTS_LOADING,
  INSURANCEFORM,
  INSURANCEFORM_ERROR,
  INSURANCEFORM_LOADING,
  LOAN,
  LOAN_EMPLOYMENT_TYPE,
  LOAN_ERROR,
  LOAN_LOADING,
  LOANFORM,
  LOANFORM_ERROR,
  LOANFORM_LOADING,
  LOANPOSTS,
  LOANPOSTS_ERROR,
  LOANPOSTS_LOADING,
  PROPERTY,
  PROPERTY_ERROR,
  PROPERTY_LOADING,
  PROPERTYFORM,
  PROPERTYFORM_ERROR,
  PROPERTYFORM_LOADING,
  PUBLIC_BLOGS,
  PUBLIC_BLOGS_ERROR,
  PUBLIC_BLOGS_LOADING,
  PUBLIC_INSTALLMENT_POSTS,
  PUBLIC_INSTALLMENT_POSTS_ERROR,
  PUBLIC_INSTALLMENT_POSTS_LOADING,
  PUBLIC_LOANPOSTS,
  PUBLIC_LOANPOSTS_ERROR,
  PUBLIC_LOANPOSTS_LOADING,
  REFFERALS,
  REQUESTED_WITHDRAW,
  REQUESTEDBLOGS,
  REQUESTEDBLOGS_LOADING,
  SIDE_BAR_FOLDABLE,
  SIDE_BAR_SHOW,
  TOP_BAR_LOADING,
  USERREWARD,
  USERREWARD_ERROR,
  USERREWARD_LOADING,
  USERS,
  USERSREWARD,
  USERSREWARD_ERROR,
  USERSREWARD_LOADING,
} from './constants'

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: true,
  properties: [],
  propertyLoading: false,
  propertyError: null,
  blogs: [],
  blogsLoading: false,
  blogsError: null,
  publicBlogs: [],
  publicBlogsLoading: false,
  publicBlogsError: null,
  loanEmpType: null,
  users: [],
  refferls: [],
  topBarLoading: 0,
  requestedBlogs: [],
  requestedBlogsLoading: false,
  userReward: null,
  userRewardLoading: false,
  userRewardError: null,
  usersReward: [],
  usersRewardLoading: false,
  usersRewardError: null,
  loan: [],
  loanError: null,
  loanLoading: false,
  approvedWithDraw: [],
  requestedWithDraw: [],
  filterPublicProperty: [],
  filterPublicPropertyLoading: false,
  filterPublicPropertyError: null,
  insuranceForm: [],
  insuranceError: null,
  insuranceLoading: false,
  loanPost: [],
  loanPostError: null,
  loanPostLoading: false,
  publicLoanPost: [],
  publicLoanPostError: null,
  publicLoanPostLoading: false,
  installmentPost: [],
  installmentPostError: null,
  installmentPostLoading: false,
  publicInstallmentPost: [],
  publicInstallmentPostError: null,
  publicInstallmentPostLoading: false,
  commonForm: [],
  commonError: null,
  commonLoading: false,
  propertyForm: [],
  propertyFormError: null,
  propertyFormLoading: false,
  loanForm: [],
  loanFormError: null,
  loanFormLoading: false,
}

const changeState = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIDE_BAR_SHOW:
      return { ...state, sidebarShow: payload }
    case SIDE_BAR_FOLDABLE:
      return { ...state, sidebarUnfoldable: payload }
    case PROPERTY:
      return { ...state, properties: payload }
    case PROPERTY_LOADING:
      return { ...state, propertyLoading: payload }
    case PROPERTY_ERROR:
      return { ...state, propertyError: payload }

    case USERS:
      return { ...state, users: payload }

    case BLOGS:
      return { ...state, blogs: payload }
    case BLOGS_LOADING:
      return { ...state, blogsLoading: payload }
    case BLOGS_ERROR:
      return { ...state, blogsError: payload }
    case PUBLIC_BLOGS:
      return { ...state, publicBlogs: payload }
    case PUBLIC_BLOGS_LOADING:
      return { ...state, publicBlogsLoading: payload }
    case PUBLIC_BLOGS_ERROR:
      return { ...state, publicBlogsError: payload }
    case FILTER_PROPERTY:
      return { ...state, filterPublicProperty: payload }
    case FILTER_PROPERTY_LOADING:
      return { ...state, filterPublicPropertyLoading: payload }
    case FILTER_PROPERTY_ERROR:
      return { ...state, filterPublicPropertyError: payload }
    case LOAN:
      return { ...state, loan: payload }
    case LOAN_LOADING:
      return { ...state, loanLoading: payload }
    case LOAN_ERROR:
      return { ...state, loanError: payload }
    case INSURANCEFORM:
      return { ...state, insuranceForm: payload }
    case INSURANCEFORM_LOADING:
      return { ...state, insuranceLoading: payload }
    case INSURANCEFORM_ERROR:
      return { ...state, insuranceError: payload }
    case COMMONFORM:
      return { ...state, commonForm: payload }
    case COMMONFORM_LOADING:
      return { ...state, commonLoading: payload }
    case COMMONFORM_ERROR:
      return { ...state, commonError: payload }
    case PROPERTYFORM:
      return { ...state, propertyForm: payload }
    case PROPERTYFORM_LOADING:
      return { ...state, propertyFormLoading: payload }
    case PROPERTYFORM_ERROR:
      return { ...state, propertyFormError: payload }
    case LOANFORM:
      return { ...state, loanForm: payload }
    case LOANFORM_LOADING:
      return { ...state, loanFormLoading: payload }
    case LOANFORM_ERROR:
      return { ...state, loanFormError: payload }
    case LOANPOSTS:
      return { ...state, loanPost: payload }
    case LOANPOSTS_LOADING:
      return { ...state, loanPostLoading: payload }
    case LOANPOSTS_ERROR:
      return { ...state, loanPostError: payload }
    case PUBLIC_LOANPOSTS:
      return { ...state, publicLoanPost: payload }
    case PUBLIC_LOANPOSTS_ERROR:
      return { ...state, publicLoanPostError: payload }
    case PUBLIC_LOANPOSTS_LOADING:
      return { ...state, publicLoanPostLoading: payload }
    case INSTALLMENT_POSTS:
      return { ...state, installmentPost: payload }
    case INSTALLMENT_POSTS_ERROR:
      return { ...state, installmentPostError: payload }
    case INSTALLMENT_POSTS_LOADING:
      return { ...state, installmentPostLoading: payload }
    case PUBLIC_INSTALLMENT_POSTS:
      return { ...state, publicInstallmentPost: payload }
    case PUBLIC_INSTALLMENT_POSTS_ERROR:
      return { ...state, publicInstallmentPostError: payload }
    case PUBLIC_INSTALLMENT_POSTS_LOADING:
      return { ...state, publicInstallmentPostLoading: payload }
    case USERREWARD:
      return { ...state, userReward: payload }
    case USERREWARD_LOADING:
      return { ...state, userRewardLoading: payload }
    case USERREWARD_ERROR:
      return { ...state, userRewardError: payload }
    case USERSREWARD:
      return { ...state, usersReward: payload }
    case USERSREWARD_LOADING:
      return { ...state, usersRewardLoading: payload }
    case USERSREWARD_ERROR:
      return { ...state, usersRewardError: payload }
    case LOAN_EMPLOYMENT_TYPE:
      return { ...state, loanEmpType: payload }
    case REFFERALS:
      return { ...state, refferls: payload }
    case TOP_BAR_LOADING:
      return { ...state, topBarLoading: payload }
    case REQUESTEDBLOGS:
      return { ...state, requestedBlogs: payload }
    case REQUESTED_WITHDRAW:
      return { ...state, requestedWithDraw: payload }
    case APPROVED_WITHDRAW:
      return { ...state, approvedWithDraw: payload }
    case REQUESTEDBLOGS_LOADING:
      return { ...state, requestedBlogsLoading: payload }
    default:
      return state
  }
}

export default changeState
