import toast from 'react-hot-toast'
// import { messaging } from './firebase'
const sendNotification = async (title, body, token) => {
  // try {
  //   if (token) {
  //     const vapidKeys = {
  //       privateKey: 'HpKCz1CP3Iz1x3bQaJv-Rm6vm0uYfoWPAjOv000xBPc',
  //       publicKey:
  //         'BGapDHAbIhJW-jKzXPyBR467mzvrYwmHuMfkEh6tzdKITh7NG9tF5MjoxQ3CGN8bHFZnXdU_N_Y8b3cI78GLw_E',
  //     }
  //     // webpush.setVapidDetails('mattikhokhar01@gmail.com', vapidKeys.publicKey, vapidKeys.privateKey)
  //     const message = {
  //       data: {
  //         score: '20',
  //         time: '2.45',
  //       },
  //       token,
  //     }
  //     const data = await messaging.send(message)
  //     console.log('data form the notification', data)
  //   } else {
  //     toast.error('Notificaiton is disabled')
  //   }
  // } catch (error) {
  //   console.log('Error while send the notification', error)
  // }
}
export const sendNotifications = async (pushToken, title, body, classData) => {
  // try {
  //   const message = {
  //     registration_ids: typeof pushToken === 'string' ? [pushToken] : pushToken,
  //     notification: {
  //       title,
  //       body,
  //       sound: 'default',
  //       priority: 'high',
  //       badge: 1,
  //     },
  //     data: {
  //       // url: "MessageScreen",
  //       // url: "BookingDetailsScreen",
  //       // classData: classData ? classData : null,
  //       // instructorId: instructorId,
  //       // instructorData: instructorData,
  //     },
  //   }
  //   const response = await fetch('https://fcm.googleapis.com/fcm/send', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       Authorization:
  //         'key=AAAAB2zOH9g:APA91bGMnD5Yer7tFQ3Pmvrb7pvIMuK6WR_ICLZCWpqk0n036hSCqbsyxMl5aq1MoifocoG3B8DkOz0XRO4hBtgYknamr-J6vVrYlH6DzLW6XUO4iP-0EMF6hs1IuezN5prj47KSzuW2',
  //     },
  //     body: JSON.stringify(message),
  //   })
  //   const responseJson = await response.json()
  //   console.log('Push notification sent: ', responseJson)
  // } catch (e) {
  //   console.log('Error in push notification: ', e)
  // }
}

export const checkPasswordStrength = (password, setErrorMessage) => {
  const minLength = 8
  const hasUppercase = /[A-Z]/.test(password)
  const hasLowercase = /[a-z]/.test(password)
  const hasNumber = /\d/.test(password)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

  if (password.length < minLength) {
    setErrorMessage('Password must be at least 8 characters long.')
    return false
  }
  if (!hasUppercase) {
    setErrorMessage('Password must contain at least one uppercase letter.')
    return false
  }
  if (!hasLowercase) {
    setErrorMessage('Password must contain at least one lowercase letter.')
    return false
  }
  if (!hasNumber) {
    setErrorMessage('Password must contain at least one number.')
    return false
  }
  if (!hasSpecialChar) {
    setErrorMessage('Password must contain at least one special character.')
    return false
  }
  setErrorMessage('Password is strong!')
  return true
}

export default sendNotification
