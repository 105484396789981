// -----------------------------Api constants-----------------------
// export const API_URL = 'http://localhost:3001/api'
// export const API_URL = 'https://16.171.251.202/api'
export const API_URL = 'https://madadgaar.com.pk/api'

// export const API_URL = 'https://app-20451079-0fa3-45c9-bf59-50bdb2bfe0e1.cleverapps.io/api'

// -----------------------------Home screen--------------------------
// Home's Hero section
export const homeData = [
  {
    title: 'Welcome to \n Madadgaar \n Platform!',
    Image: '/Media/inshero.png',
    description:
      "We're here to make your life easier with our awesome services. Whether you need property solutions, insurance support, loans, or installment plans, we’re dedicated to assisting you with care and commitment. On our platform, you can compare multiple options across Pakistan to find the best fit for your needs. Let's make things easier & happen together!",
  },
  // {
  //   title: 'Benefits \n With Us',
  //   Image: '/Media/beni frame.png',
  //   description:
  //     "We're here to make your life easier with our awesome services.\n Whether you need property services, insurance services, loan \n services or want to participate in any govt Scheme.",
  // },
  // {
  //   title: 'What we \n serve?',
  //   Image: '/Media/man 1.png',
  //   description: 'Were you promised free health insurance when you \n purchased life insurance?',
  // },
  // {
  //   title: 'Opportunity \n with us?',
  //   Image: '/Media/man 2.png',
  //   description: 'Were you promised free health insurance when you \n purchased life insurance?',
  // },
  // {
  //   title: 'Who We \n Are?',
  //   Image: '/Media/man 3.png',
  //   description: 'Were you promised free health insurance when you \n purchased life insurance?',
  // },
]
export const homeSlidesData = [
  {
    title: 'Property',
    description: `We are offering a wide range of property options across Pakistan. We partner with leading companies, societies, and dealers to provide tailored real estate solutions that fit your needs. Our service ensures a smooth and efficient property search, whether you're buying, selling, or renting. With extensive options available nationwide, we make your property transactions hassle-free and convenient.`,
    Image: '/Media/Hero.png',
  },
  {
    title: 'Loan',
    Image: '/Media/Frame-loan.png',
    description:
      'We are offering easy and accessible financing options to help you achieve your goals. We partner with banking sectors, social societies, and organizations to offer customized loan solutions tailored to your needs. Our service simplifies the loan process, making it quicker and more convenient to secure the funds you need. With a focus on flexibility and support, we help you turn your ambitions into reality.',
  },
  {
    title: 'Installments',
    Image: '/Media/installment.jpeg',
    description:
      'We are offering a wide range of installment Plans across Pakistan. We partner with leading companies and dealers to provide tailored payment solutions that fit your needs. With manageable installments and often low or zero interest rates, this service makes large purchases more affordable and accessible, ensuring you can get what you need without financial strain.',
  },
  {
    title: 'Insurance',
    Image: '/Media/ins Frame.png',
    description:
      'We are offers reliable support for addressing insurance-related issues. We partner with leading insurance companies to ensure your concerns are handled effectively and fairly. Our platform is dedicated to providing swift resolutions, helping you navigate the complexities of insurance claims with ease. With a focus on transparency and customer satisfaction, we are your go-to resource for resolving insurance complaints in Pakistan.',
  },
]

// Hero's card component

export const cardsData = [
  {
    number: '/Media/1.png',
    icon: '/Media/H icon-2.png',
    title: 'Property',
    description: `Pakistan's most trusted platform for resolving
    insurance complaints`,
    name: 'insurance',
  },
  {
    number: '/Media/2.png',
    icon: '/Media/H icon-3.png',
    title: 'Loan',
    description: 'Your Gateway to Seamless Property Solutions.',
    name: 'property',
  },
  {
    icon: '/Media/H icon3.jpeg',
    number: '/Media/3.png',
    title: 'Installment',
    description: 'Empower your ambitions with our seamless loan solutions.',
    name: 'cashloan',
  },
  {
    number: '/Media/4.png',
    icon: '/Media/H icon-1.png',
    title: 'Insurance',
    description: 'This encompasses various types of insurance to protect businesses.',
    name: 'installments',
  },
]

// Benefit's Component

export const benefitsCardData = [
  {
    title: '15000+',
    description: 'Resolved \n Cases',
  },
  {
    title: '100Cr+',
    description: 'Worth of our \n success',
  },
  {
    title: '10000+',
    description: 'Happy Customers \n across Pakistan',
  },
  {
    title: '3500+',
    description: 'Strong Partner \n Network',
  },
]

export const benefitsData = [
  {
    icon: '/Media/man 1.png',
    title: 'What we serve?',
    description: 'Were you promised free health insurance when you purchased life insurance?',
  },
  {
    icon: '/Media/man 2.png',
    title: 'Opportunity with us?',
    description: 'Were you promised free health insurance when you purchased life insurance?',
  },
  {
    icon: '/Media/man 3.png',
    title: 'Who We Are?',
    description: 'Were you promised free health insurance when you purchased life insurance?',
  },
]

export const aboutData = [
  {
    icon: '/Media/Agent-1.png',
    title: 'Our Vision',
    description:
      'Madadgaar Expert Partner has a highly efficient team of experienced insurance consultants who can always assist you. Our team is equipped to address any kind of issue you may encounter with your insurance policy.',
  },
  {
    icon: '/Media/Agent-2.png',
    title: 'Our Mission',
    description:
      "We understand that customer satisfaction is paramount and we are committed to providing a hassle-free experience for all our clients. That's why we do not charge any success fee until the case is resolved.",
  },
  {
    icon: '/Media/Agent-3.png',
    title: 'Our Network',
    description:
      'We recognise the importance of our customers and are committed to providing them with the highest level of service. Our goal is to create a positive impact in the lives of our customers by delivering exceptional value through our services.',
  },
]

// -----------------------------Blog screen--------------------------

//Agent component

export const agentData = [
  {
    icon: '/Media/Agent-1.png',
    title: 'Team of Industry Experts',
    description:
      'Madadgaar Expert Partner has a highly efficient team of experienced insurance consultants who can always assist you. Our team is equipped to address any kind of issue you may encounter with your insurance policy.',
  },
  {
    icon: '/Media/Agent-2.png',
    title: 'No Upfront Charges  ',
    description:
      "We understand that customer satisfaction is paramount and we are committed to providing a hassle-free experience for all our clients. That's why we do not charge any success fee until the case is resolved.",
  },
  {
    icon: '/Media/Agent-3.png',
    title: 'Customer-First Approach',
    description:
      'We recognise the importance of our customers and are committed to providing them with the highest level of service. Our goal is to create a positive impact in the lives of our customers by delivering exceptional value through our services.',
  },
]

// -----------------------------Insurance screen--------------------------

// Insurance Service

export const insuranceIcon = [
  {
    icon: '/Media/ins 1.png',
    title: 'Life Insurance',
  },
  {
    icon: '/Media/ins 2.png',
    title: 'General Insurance  ',
  },
  {
    icon: '/Media/ins 3.png',
    title: 'Health Insurance',
  },
  {
    icon: '/Media/ins 4.png',
    title: 'Term Insurance',
  },
  {
    icon: '/Media/ins 5.png',
    title: 'Travel Insurance',
  },
  {
    icon: '/Media/ins 6.png',
    title: 'Motor Insurance',
  },
]

//Process cards

export const processCard = [
  {
    icon: '01',
    title: 'Reach out to us',
    description:
      'Kindly fill out the form with your name, email, and  contact number. Alternatively, you can contact our claim  experts by calling us at +92 34 450 4333.',
  },
  {
    icon: '02',
    title: 'Share case documents',
    description: 'We hear you out and ask you to share copies of case  related documents with us.',
  },
  {
    icon: '03',
    title: 'Case Acceptance',
    description:
      'We will proceed with your insurance complaint after  thoroughly reviewing your case particulars and  insurance paperwork.',
  },
  {
    icon: '04',
    title: 'Registration',
    description:
      'We do not charge anything upfront other than a one-  time registration fee of Rs5000 only after case acceptance.',
  },
]

// -----------------------------Loan screen--------------------------

// Overview component

export const overData = [
  {
    icon: '/Media/Overview 2.png',
    title: 'Business Loans',
  },
  {
    icon: '/Media/Overview 3.png',
    title: 'Home Equity Loans',
  },
  {
    icon: '/Media/Overview 4.png',
    title: 'Dept-Consolidation Loan',
  },
  {
    icon: '/Media/Overview 5.png',
    title: 'Auto Loans',
  },
  {
    icon: '/Media/Overview 6.png',
    title: 'Term Loans',
  },
  {
    icon: '/Media/Overview 1.png',
    title: 'Startup Loans',
  },
]

// -----------------------------Properties screen--------------------------

// Properties card

export const proCard = [
  {
    icon: '/Media/pro 1.png',
    title: 'Corporate Offices',
    location: 'Pearl Business Center',
    city: 'Islamabad, Pakistan',
    booking: 'PKR 378,000',
    installment: 'PKR 252,000',
  },
  {
    icon: '/Media/pro 2.png',
    title: '5 Marla Residential Plot',
    location: 'Sports Valley, Blue World City',
    city: 'Islamabad, Pakistan',
    booking: 'PKR 150,000',
    installment: 'PKR 26,125',
  },
  {
    icon: '/Media/pro 3.png',
    title: '3 Marla Residential Plot',
    location: 'Etihad Town',
    city: 'Lahore, Pakistan',
    booking: 'PKR 150,000',
    installment: 'PKR 375,000',
  },
  {
    icon: '/Media/pro 4.png',
    title: '4.5 Marla Residential Plot',
    location: 'Awami Block, Blue World City',
    city: 'Islamabad, Pakistan',
    booking: 'PKR 87,500',
    installment: 'PKR 9,750',
  },
]
// Properties Page
// Residense Component
export const ResidenseCard = [
  {
    icon: '/Media/Home.png',
    title: 'Home',
    // Popular
    popularItemOne: '5 Marla',
    popularSubItemOne: 'House',
    popularItemTwo: '10 Marla',
    popularSubItemTwo: 'House',
    popularItemThree: '3 Marla',
    popularSubItemThree: 'House',
    popularItemFour: 'New',
    popularSubItemFour: 'House',
    popularItemFive: 'Low Price',
    popularSubItemFive: 'All House',
    popularItemSix: 'Small',
    popularSubItemSix: 'House',
    // Type
    typeItemOne: 'Houses',
    typeItemTwo: 'Flats',
    typeItemThree: 'Upper Portion',
    typeItemFour: 'Lower Portion',
    typeItemFive: 'Farmhouse',
    typeItemSix: 'Penthouse',
    // Area Size
    areaItemOne: '5 Marla',
    areaSubItemOne: 'House',
    areaItemTwo: '3 Marla',
    areaSubItemTwo: 'House',
    areaItemThree: '7 Marla',
    areaSubItemThree: 'House',
    areaItemFour: '8',
    areaSubItemFour: 'House',
    areaItemFive: '10',
    areaSubItemFive: 'House',
    areaItemSix: '1 Kanal',
    areaSubItemSix: 'House',
  },
  {
    icon: '/Media/loc.png',
    title: 'Plots',
    // Popular
    popularItemOne: '5 Marla',
    popularSubItemOne: 'Residential',
    popularItemTwo: '10 Marla',
    popularSubItemTwo: 'Residential',
    popularItemThree: '3 Marla',
    popularSubItemThree: 'Residential',
    popularItemFour: '7 Marla',
    popularSubItemFour: 'Residential',
    popularItemFive: 'On Installments',
    popularSubItemFive: 'Residential',
    popularItemSix: 'On Installments',
    popularSubItemSix: 'Commercial',
    // Type
    typeItemOne: 'On Installments',
    typeItemTwo: 'Commercial Plots',
    typeItemThree: 'Plot File',
    typeItemFour: 'Plot Form',
    typeItemFive: 'Agricultural Land',
    typeItemSix: 'Industrial Land',
    // Area Size
    areaItemOne: '5 Marla',
    areaSubItemOne: 'Plots',
    areaItemTwo: '3 Marla',
    areaSubItemTwo: 'Plots',
    areaItemThree: '7 Marla',
    areaSubItemThree: 'Plots',
    areaItemFour: '8',
    areaSubItemFour: 'Plots',
    areaItemFive: '10',
    areaSubItemFive: 'Plots',
    areaItemSix: '1 Kanal',
    areaSubItemSix: 'Plots',
  },
  {
    icon: '/Media/com.png',
    title: 'Commercial',
    // Popular
    popularItemOne: 'Small',
    popularSubItemOne: 'Offices',
    popularItemTwo: 'New',
    popularSubItemTwo: 'Offices',
    popularItemThree: 'Repayment',
    popularSubItemThree: 'Shops',
    popularItemFour: 'Small',
    popularSubItemFour: 'Shops',
    popularItemFive: 'New',
    popularSubItemFive: 'Shops',
    popularItemSix: 'Running',
    popularSubItemSix: 'Shops',
    // Type
    typeItemOne: 'Office',
    typeItemTwo: 'Shops',
    typeItemThree: 'Building',
    typeItemFour: 'Warehouse',
    typeItemFive: 'Factory',
    typeItemSix: 'Others',
    // Area Size
    areaItemOne: 'Less than 100 sq..',
    areaSubItemOne: 'Commercial',
    areaItemTwo: '100-200 sq ft',
    areaSubItemTwo: 'Commercial',
    areaItemThree: '200-300 sq ft',
    areaSubItemThree: 'Commercial',
    areaItemFour: '300-400 sq ft',
    areaSubItemFour: 'Commercial',
    areaItemFive: '400-500 sq ft',
    areaSubItemFive: 'Commercial',
    areaItemSix: 'More than 500 s..',
    areaSubItemSix: 'Commercial',
  },
]

export const FILTER_INTERESTS = [
  {
    id: 'filter_interests_1',
    label: 'Property',
    value: 'interests/property',
  },
  {
    id: 'filter_interests_2',
    label: 'Financial',
    value: 'interests/financial',
  },
  {
    id: 'filter_interests_3',
    label: 'Insurance',
    value: 'interests/insurance',
  },
  {
    id: 'filter_interests_4',
    label: 'Public Welfare',
    value: 'interests/public_welfare',
  },
]

export const FILTER_LOAN_TYPE = [
  {
    id: 'FILTER_LOAN_TYPE_1',
    label: 'Personal Loan',
    value: 'loantype/Personal Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_2',
    label: 'Fori Cash Loan',
    value: 'loantype/Fori Cash Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_3',
    label: 'Mortgage Loan / Home',
    value: 'loantype/Mortgage Loan / Home',
  },
  {
    id: 'FILTER_LOAN_TYPE_4',
    label: 'SME Loan',
    value: 'loantype/SME Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_5',
    label: 'Agri Loan',
    value: 'loantype/Agri Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_6',
    label: 'Corporate / Commercial Loan',
    value: 'loantype/Corporate / Commercial Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_7',
    label: 'Scheme Loan',
    value: 'loantype/Scheme Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_8',
    label: 'Bachat Loan',
    value: 'loantype/Bachat Loan',
  },
  {
    id: 'FILTER_LOAN_TYPE_9',
    label: 'Interest Free Loan',
    value: 'loantype/Interest Free Loan',
  },
]
export const USER_ATTRIBUTES = [
  {
    id: 'USER_ATTRIBUTES_1',
    label: 'ID',
    value: 'id',
  },
  {
    id: 'USER_ATTRIBUTES_2',
    label: 'Name',
    value: 'name',
  },
  {
    id: 'USER_ATTRIBUTES_3',
    label: 'Phone',
    value: 'phone',
  },
  {
    id: 'USER_ATTRIBUTES_4',
    label: 'Email',
    value: 'email',
  },
  {
    id: 'USER_ATTRIBUTES_4',
    label: 'Cinc',
    value: 'cinc',
  },
]
export const PROPERTY_ATTRIBUTES = [
  {
    id: 'PROPERTY_ATTRIBUTES_1',
    label: 'ID',
    value: 'id',
  },
  {
    id: 'PROPERTY_ATTRIBUTES_2',
    label: 'Name',
    value: 'name',
  },
  {
    id: 'PROPERTY_ATTRIBUTES_2',
    label: 'Project Name',
    value: 'proname',
  },
  {
    id: 'PROPERTY_ATTRIBUTES_3',
    label: 'Phone',
    value: 'phone',
  },
  {
    id: 'PROPERTY_ATTRIBUTES_4',
    label: 'Email',
    value: 'email',
  },
]
export const POST_LOAN = [
  {
    id: 'POST_LOAN_1',
    label: 'ID',
    value: 'id',
  },
  {
    id: 'POST_LOAN_2',
    label: 'Title',
    value: 'title',
  },
  {
    id: 'POST_LOAN_2',
    label: 'Amount',
    value: 'loanAmount',
  },
]
export const POST_INSTALLMENT = [
  {
    id: 'POST_INSTALLMENT_1',
    label: 'ID',
    value: 'id',
  },
  {
    id: 'POST_INSTALLMENT_2',
    label: 'Product Name',
    value: 'productname',
  },
  {
    id: 'POST_INSTALLMENT_3',
    label: 'Price',
    value: 'price',
  },
  {
    id: 'POST_INSTALLMENT_3',
    label: 'Down Payment',
    value: 'downpayment',
  },
]

export const POST_TENURE = [
  { id: 'POST_TENURE_1', label: '3 Months', value: '3_months' },
  { id: 'POST_TENURE_2', label: '6 Months', value: '6_months' },
  { id: 'POST_TENURE_3', label: '1 Year', value: '1_year' },
]
export const CATEGORY = [
  {
    label: '-select-',
    value: '',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Bikes',
    value: 'bikes',
  },
  {
    label: 'Air Conditioners',
    value: 'air_conditioners',
  },
  {
    label: 'Television',
    value: 'television',
  },
  {
    label: 'Electronics',
    value: 'electronics',
  },
  {
    label: 'Home Appliances',
    value: 'home_appliances',
  },
]
export const BIKES_COMPANY_NAME = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Honda', value: 'honda' },
  { label: 'Yamaha', value: 'yamaha' },
  { label: 'Suzuki', value: 'suzuki' },
]
export const AIR_CONDITIONERS = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Daikin', value: 'daikin' },
  { label: 'LG', value: 'lg' },
  { label: 'Samsung', value: 'samsung' },
  { label: 'Mitsubishi', value: 'mitsubishi' },
  { label: 'Carrier', value: 'carrier' },
  { label: 'Hitachi', value: 'hitachi' },
  { label: 'Panasonic', value: 'panasonic' },
  { label: 'Voltas', value: 'voltas' },
  { label: 'Blue Star', value: 'blue_star' },
  { label: 'Whirlpool', value: 'whirlpool' },
]
export const TELEVISIONS = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Samsung', value: 'samsung' },
  { label: 'LG', value: 'lg' },
  { label: 'Sony', value: 'sony' },
  { label: 'TCL', value: 'tcl' },
  { label: 'Panasonic', value: 'panasonic' },
  { label: 'Philips', value: 'philips' },
  { label: 'Vizio', value: 'vizio' },
  { label: 'Hisense', value: 'hisense' },
  { label: 'Sharp', value: 'sharp' },
  { label: 'Toshiba', value: 'toshiba' },
]
export const ELECTRONICS = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Apple', value: 'apple' },
  { label: 'Samsung', value: 'samsung' },
  { label: 'Sony', value: 'sony' },
  { label: 'LG', value: 'lg' },
  { label: 'Panasonic', value: 'panasonic' },
  { label: 'Philips', value: 'philips' },
  { label: 'Bose', value: 'bose' },
  { label: 'HP', value: 'hp' },
  { label: 'Dell', value: 'dell' },
  { label: 'Lenovo', value: 'lenovo' },
]
export const HOME_APPLIANCES = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Whirlpool', value: 'whirlpool' },
  { label: 'LG', value: 'lg' },
  { label: 'Samsung', value: 'samsung' },
  { label: 'Bosch', value: 'bosch' },
  { label: 'GE Appliances', value: 'ge_appliances' },
  { label: 'Electrolux', value: 'electrolux' },
  { label: 'Panasonic', value: 'panasonic' },
  { label: 'Philips', value: 'philips' },
  { label: 'Frigidaire', value: 'frigidaire' },
  { label: 'KitchenAid', value: 'kitchenaid' },
]
export const PHONE = [
  {
    label: '-select-',
    value: '',
  },
  { label: 'Apple', value: 'apple' },
  { label: 'Samsung', value: 'samsung' },
  { label: 'Huawei', value: 'huawei' },
  { label: 'Xiaomi', value: 'xiaomi' },
  { label: 'OnePlus', value: 'oneplus' },
  { label: 'Google', value: 'google' },
  { label: 'Sony', value: 'sony' },
  { label: 'LG', value: 'lg' },
  { label: 'Nokia', value: 'nokia' },
  { label: 'Oppo', value: 'oppo' },
  { label: 'Vivo', value: 'vivo' },
  { label: 'Realme', value: 'realme' },
  { label: 'Motorola', value: 'motorola' },
  { label: 'Asus', value: 'asus' },
  { label: 'ZTE', value: 'zte' },
]
export const COMPANY_NAME = {
  phone: PHONE,
  bikes: BIKES_COMPANY_NAME,
  air_conditioners: AIR_CONDITIONERS,
  television: TELEVISIONS,
  electronics: ELECTRONICS,
  home_appliances: HOME_APPLIANCES,
}
export const countries = [
  { label: 'United States', value: 'united_states' },
  { label: 'Canada', value: 'canada' },
  { label: 'United Kingdom', value: 'united_kindom' },
  { label: 'Pakistan', value: 'pakistan' },
  // Add more countries as needed
]
export const districts = [
  // Sample districts for a few provinces
  { label: 'Karachi', value: 'Karachi' },
  { label: 'Lahore', value: 'Lahore' },
  { label: 'Islamabad', value: 'Islamabad' },
  { label: 'Peshawar', value: 'Peshawar' },
  { label: 'Quetta', value: 'Quetta' },
  { label: 'Faisalabad', value: 'Faisalabad' },
  { label: 'Multan', value: 'Multan' },
  { label: 'Rawalpindi', value: 'Rawalpindi' },
  { label: 'Gujranwala', value: 'Gujranwala' },
  { label: 'Sialkot', value: 'Sialkot' },
  // Add more districts as needed
]
export const cities = [
  { label: 'Karachi', value: 'Karachi' },
  { label: 'Lahore', value: 'Lahore' },
  { label: 'Islamabad', value: 'Islamabad' },
  { label: 'Rawalpindi', value: 'Rawalpindi' },
  { label: 'Faisalabad', value: 'Faisalabad' },
  { label: 'Multan', value: 'Multan' },
  { label: 'Peshawar', value: 'Peshawar' },
  { label: 'Quetta', value: 'Quetta' },
  { label: 'Gujranwala', value: 'Gujranwala' },
  { label: 'Sialkot', value: 'Sialkot' },
  { label: 'Sukkur', value: 'Sukkur' },
  { label: 'Larkana', value: 'Larkana' },
  { label: 'Hyderabad', value: 'Hyderabad' },
  { label: 'Sargodha', value: 'Sargodha' },
  { label: 'Bahawalpur', value: 'Bahawalpur' },
  { label: 'Sahiwal', value: 'Sahiwal' },
  { label: 'Rahim Yar Khan', value: 'Rahim Yar Khan' },
  { label: 'Sheikhupura', value: 'Sheikhupura' },
  { label: 'Mardan', value: 'Mardan' },
  { label: 'Kasur', value: 'Kasur' },
  { label: 'Gujrat', value: 'Gujrat' },
  { label: 'Mingora', value: 'Mingora' },
  { label: 'Chiniot', value: 'Chiniot' },
  { label: 'Nawabshah', value: 'Nawabshah' },
  { label: 'Jhelum', value: 'Jhelum' },
  { label: 'Sadiqabad', value: 'Sadiqabad' },
  { label: 'Mirpur Khas', value: 'Mirpur Khas' },
  { label: 'Okara', value: 'Okara' },
  { label: 'Mandi Bahauddin', value: 'Mandi Bahauddin' },
  { label: 'Dera Ghazi Khan', value: 'Dera Ghazi Khan' },
  { label: 'Vehari', value: 'Vehari' },
  { label: 'Nowshera', value: 'Nowshera' },
  { label: 'Kamoke', value: 'Kamoke' },
  { label: 'Khanewal', value: 'Khanewal' },
  { label: 'Kotli', value: 'Kotli' },
  { label: 'Hafizabad', value: 'Hafizabad' },
  { label: 'Muzaffargarh', value: 'Muzaffargarh' },
  { label: 'Jacobabad', value: 'Jacobabad' },
  { label: 'Kohat', value: 'Kohat' },
  { label: 'Abbottabad', value: 'Abbottabad' },
  { label: 'Chakwal', value: 'Chakwal' },
  { label: 'Charsadda', value: 'Charsadda' },
  { label: 'Dera Ismail Khan', value: 'Dera Ismail Khan' },
  { label: 'Jhang', value: 'Jhang' },
  { label: 'Kamalia', value: 'Kamalia' },
  { label: 'Kandhkot', value: 'Kandhkot' },
  { label: 'Khuzdar', value: 'Khuzdar' },
  { label: 'Mianwali', value: 'Mianwali' },
  { label: 'Mandi Bahauddin', value: 'Mandi Bahauddin' },
  { label: 'Pakpattan', value: 'Pakpattan' },
  { label: 'Daska', value: 'Daska' },
  { label: 'Tando Allahyar', value: 'Tando Allahyar' },
  { label: 'Tando Muhammad Khan', value: 'Tando Muhammad Khan' },
  { label: 'Sanghar', value: 'Sanghar' },
  { label: 'Shikarpur', value: 'Shikarpur' },
  { label: 'Ghotki', value: 'Ghotki' },
  { label: 'Kharian', value: 'Kharian' },
  { label: 'Muridke', value: 'Muridke' },
  { label: 'Kot Addu', value: 'Kot Addu' },
  { label: 'Bhalwal', value: 'Bhalwal' },
  { label: 'Jaranwala', value: 'Jaranwala' },
  { label: 'Pattoki', value: 'Pattoki' },
  { label: 'Haripur', value: 'Haripur' },
  { label: 'Khanpur', value: 'Khanpur' },
  { label: 'Gojra', value: 'Gojra' },
  { label: 'Kamalia', value: 'Kamalia' },
  { label: 'Nankana Sahib', value: 'Nankana Sahib' },
  { label: 'Samundri', value: 'Samundri' },
  { label: 'Pasrur', value: 'Pasrur' },
  { label: 'Tando Adam', value: 'Tando Adam' },
  { label: 'Hasilpur', value: 'Hasilpur' },
  { label: 'Arifwala', value: 'Arifwala' },
  { label: 'Fort Abbas', value: 'Fort Abbas' },
  { label: 'Narowal', value: 'Narowal' },
  { label: 'Chishtian', value: 'Chishtian' },
  { label: 'Khairpur', value: 'Khairpur' },
  { label: 'Jatoi', value: 'Jatoi' },
  { label: 'Mailsi', value: 'Mailsi' },
  { label: 'Mian Channu', value: 'Mian Channu' },
  { label: 'Turbat', value: 'Turbat' },
  { label: 'Umerkot', value: 'Umerkot' },
  { label: 'Gujar Khan', value: 'Gujar Khan' },
  { label: 'Toba Tek Singh', value: 'Toba Tek Singh' },
  { label: 'Mehar', value: 'Mehar' },
  { label: 'Kot Radha Kishan', value: 'Kot Radha Kishan' },
  { label: 'Hujra Shah Muqeem', value: 'Hujra Shah Muqeem' },
  { label: 'Jalalpur Jattan', value: 'Jalalpur Jattan' },
  { label: 'Darya Khan', value: 'Darya Khan' },
  { label: 'Zhob', value: 'Zhob' },
  { label: 'Layyah', value: 'Layyah' },
  { label: 'Narowal', value: 'Narowal' },
  { label: 'Sadiqabad', value: 'Sadiqabad' },
  { label: 'Nankana Sahib', value: 'Nankana Sahib' },
  { label: 'Khairpur', value: 'Khairpur' },
  // Add more cities as needed
]
export const banksAndWallets = [
  { label: '--Select Bank--', value: '' },
  { label: 'Allied Bank', value: 'allied_bank' },
  { label: 'Askari Bank', value: 'askari_bank' },
  { label: 'Bank Alfalah', value: 'bank_alfalah' },
  { label: 'Bank Islami Pakistan', value: 'bank_islami_pakistan' },
  { label: 'Bank of Khyber', value: 'bank_of_khyber' },
  { label: 'Bank of Punjab', value: 'bank_of_punjab' },
  { label: 'Dubai Islamic Bank', value: 'dubai_islamic_bank' },
  { label: 'Faysal Bank', value: 'faysal_bank' },
  { label: 'First Women Bank', value: 'first_women_bank' },
  { label: 'Habib Bank Limited (HBL)', value: 'habib_bank_limited' },
  { label: 'Habib Metropolitan Bank', value: 'habib_metropolitan_bank' },
  { label: 'JS Bank', value: 'js_bank' },
  { label: 'MCB Bank Limited', value: 'mcb_bank_limited' },
  { label: 'Meezan Bank', value: 'meezan_bank' },
  { label: 'National Bank of Pakistan (NBP)', value: 'national_bank_of_pakistan' },
  { label: 'Silk Bank', value: 'silk_bank' },
  { label: 'Sindh Bank', value: 'sindh_bank' },
  { label: 'Soneri Bank', value: 'soneri_bank' },
  { label: 'Standard Chartered Bank', value: 'standard_chartered_bank' },
  { label: 'Summit Bank', value: 'summit_bank' },
  { label: 'United Bank Limited (UBL)', value: 'united_bank_limited' },
  { label: 'Zarai Taraqiati Bank Limited (ZTBL)', value: 'zarai_taraqiati_bank_limited' },
  { label: 'Al Baraka Bank', value: 'al_baraka_bank' },
  { label: 'Samba Bank', value: 'samba_bank' },
  { label: 'Mobilink Microfinance Bank', value: 'mobilink_microfinance_bank' },
  { label: 'U Microfinance Bank', value: 'u_microfinance_bank' },
  { label: 'FINCA Microfinance Bank', value: 'finca_microfinance_bank' },
  { label: 'Pak Oman Microfinance Bank', value: 'pak_oman_microfinance_bank' },
  { label: 'Khushhali Microfinance Bank', value: 'khushhali_microfinance_bank' },
  { label: 'First Microfinance Bank', value: 'first_microfinance_bank' },
  { label: 'NRSP Microfinance Bank', value: 'nrsp_microfinance_bank' },
  { label: 'Apna Microfinance Bank', value: 'apna_microfinance_bank' },
  { label: 'Advans Pakistan Microfinance Bank', value: 'advans_pakistan_microfinance_bank' },
  { label: 'FINJA (SimSim Wallet)', value: 'finja_simsim_wallet' },
  { label: 'JazzCash', value: 'jazzcash' },
  { label: 'Easypaisa', value: 'easypaisa' },
]
