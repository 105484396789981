import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ButtonWithLoader from 'src/components/ButtonWithLoader'
import Footer from 'src/components/Clientside/Footer'
import Header from 'src/components/Clientside/Header'
import Hero from 'src/components/Clientside/Hero'
import HeroCard from 'src/components/Clientside/HeroCard'
import MadadghaarAgent from 'src/components/Clientside/MadadghaarAgent'
import Modal from 'src/components/Clientside/Modal'
import OurProject from 'src/components/Clientside/OurProjects'
import PropertiesCard from 'src/components/Clientside/PropertiesCard'
import PropertiesServicesCard from 'src/components/Clientside/PropertiesServicesCard'

import { getAllPublicInstallmentPosts, searhPropertyForPublic } from 'src/store/action'
import DropDown from 'src/components/Clientside/DropDown'
import { Carousel } from 'react-bootstrap'
import InstallmentCard from 'src/components/Clientside/InstallmentCard'
import {
  AIR_CONDITIONERS,
  BIKES_COMPANY_NAME,
  ELECTRONICS,
  HOME_APPLIANCES,
  PHONE,
  TELEVISIONS,
} from 'src/utils/constants'
const INITIAL_VALUE = {
  companyName: '',
  category: '',
  tenure: '',
  price: 0,
  downpayment: 0,
}
const Installments = () => {
  const [filter, setFilter] = useState(INITIAL_VALUE)
  const dispatch = useDispatch()
  const { publicInstallmentPostLoading } = useSelector((state) => state.common)
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value)

    setFilter((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })
  }
  const handleDropDownChange = (e) => {
    const { name, value } = e.target
    setFilter((prev) => {
      return {
        ...prev,
        category: name,
        companyName: value,
      }
    })
  }
  const handleSearch = (e) => {
    e.preventDefault()
    console.log(filter)

    try {
      dispatch(getAllPublicInstallmentPosts(filter))
    } catch (error) {}
  }
  const handleReset = (e) => {
    e.preventDefault()
    console.log(filter)
    setFilter(INITIAL_VALUE)
    try {
      dispatch(getAllPublicInstallmentPosts({}))
    } catch (error) {}
  }
  return (
    <>
      <Header />
      <Hero
        title={[<div key="line1">Get What You Need Today, Pay in Easy Installments.</div>]}
        imageUrl={process.env.PUBLIC_URL + '/Media/installment.jpeg'}
        customStyle={{ maxWidth: '100%', objectFit: 'cover' }}
        paragraph="We are offering a wide range of installment Plans across Pakistan. We partner with leading companies and dealers to provide tailored payment solutions that fit your needs. With manageable installments and often low or zero interest rates, this service makes large purchases more affordable and accessible, ensuring you can get what you need without financial strain."
      />
      <div className="border-bottom p-2 border-top container">
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2">
          <DropDown
            title={'Mobile Phones'}
            name={'phone'}
            items={PHONE}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
          <DropDown
            title={'Bikes'}
            name={'bikes'}
            items={BIKES_COMPANY_NAME}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
          <DropDown
            title={'Air Conditioners'}
            items={AIR_CONDITIONERS}
            name={'air_conditioners'}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
          <DropDown
            title={'Televisions'}
            name={'television'}
            items={TELEVISIONS}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
          <DropDown
            title={'Electronics'}
            items={ELECTRONICS}
            name={'electronics'}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
          <DropDown
            title={'Home Appliances'}
            items={HOME_APPLIANCES}
            name={'home_appliances'}
            onClick={(e) => {
              handleDropDownChange(e)
            }}
          />
        </div>
      </div>
      {/* filters */}
      <div className="my-5">
        <div className="w-100 my-2">
          <div
            className="d-flex p-2 justify-content-center align-items-center flex-column flex-md-row gap-5 w-75 mx-auto"
            // style={{ background: '#f98387' }}
          >
            <div className="d-flex p-2 flex-column flex-md-row justify-content-center align-items-center gap-5 w-100 w-md-75 mx-auto">
              <CFormSelect
                aria-label="Purpose"
                name="tenure"
                style={{ width: '10%', backgroundColor: '	#D3D3D3' }}
                className="w-sm-50"
                value={filter.tenure}
                onChange={handleChange}
                options={[
                  { label: '--tenure--', value: '' },
                  { label: '3 Months', value: '3_months' },
                  { label: '6 Months', value: '6_months' },
                  { label: '1 Year', value: '1_year' },
                ]}
              />

              <CFormSelect
                aria-label="loanAmount"
                name="price"
                style={{ width: '10%', backgroundColor: '	#D3D3D3' }}
                className="w-sm-50 w-50"
                value={filter.price}
                onChange={handleChange}
                options={[
                  { label: '--Price--', value: '' },
                  { label: '1000', value: '1000' },
                  { label: '10000', value: '10000' },
                  { label: '1000000', value: '1000000' },
                ]}
                // onChange={(e) => handleChange(e)}
              />

              {/* <CFormInput
                  type="text"
                  id="exampleFormControlInput1"
                  placeholder="Title"
                  name="title"
                  aria-describedby="exampleFormControlInputHelpInline"
                  onChange={handleChange}
                /> */}
              <ButtonWithLoader loading={publicInstallmentPostLoading} onClick={handleSearch}>
                Search
              </ButtonWithLoader>
              <ButtonWithLoader loading={publicInstallmentPostLoading} onClick={handleReset}>
                Reset
              </ButtonWithLoader>
            </div>
          </div>
        </div>
      </div>
      {/* <HeroCard /> */}
      <InstallmentCard />
      <div className="container">
        <h2 className="text-center my-3">Special Offers for limited time</h2>
        <Carousel className={'w-100'}>
          <Carousel.Item>
            <img
              style={{ objectFit: 'contain', maxHeight: '500px' }}
              className="d-block w-100"
              src={
                'https://www.hcsupermart.com/wp-content/uploads/2024/01/3-2-HOME-APPLIANCES-2-1.jpg'
              }
            />
          </Carousel.Item>
        </Carousel>
      </div>
      {/* <PropertiesCard /> */}
      <div style={{ height: '60vh' }} className="w-75 container my-5">
        <iframe
          className="object-fit-contain w-100 h-100"
          src="https://www.youtube.com/embed/X7rTL_Y54oI?si=FCuyEAuHBOXhizhu"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <OurProject />

      <MadadghaarAgent title={['Why Madadghaar?']} />
      <Footer />
    </>
  )
}

export default Installments
