import {
  FORGOT_PASSWORD_STATE,
  LOGOUT,
  SIGNUP,
  SIGNUP_ERROR,
  SIGNUP_LOADING,
  UPDATE_USER_PROFILE,
} from './constants'

const initialState = {
  authDetails: null,
  authToken: null,
  authLoading: false,
  authError: null,
  forgotPassword: null,
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGNUP:
      return { ...state, authDetails: payload.user, authToken: payload.access_token }
    case UPDATE_USER_PROFILE:
      return { ...state, authDetails: payload }
    case SIGNUP_LOADING:
      return { ...state, authLoading: payload }
    case FORGOT_PASSWORD_STATE:
      return { ...state, forgotPassword: payload }
    case SIGNUP_ERROR:
      return { ...state, authError: payload }
    case LOGOUT:
      return { ...state, authDetails: null, authToken: null, authLoading: false, authError: null }

    default:
      return state
  }
}

export default authReducer
