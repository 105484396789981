export const SIGNUP = 'SIGNUP'
export const SIGNUP_LOADING = 'SIGNUP_LOADING'
export const SIGNUP_ERROR = 'SIGNUP_ERROR'
export const FORGOT_PASSWORD_STATE = 'FORGOT_PASSWORD_STATE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'

export const SIDE_BAR_SHOW = 'SIDE_BAR_SHOW'
export const SIDE_BAR_FOLDABLE = 'SIDE_BAR_FOLDABLE'

export const LOGOUT = 'LOGOUT'

export const PROPERTY = 'PROPERTY'
export const PROPERTY_LOADING = 'PROPERTY_LOADING'
export const PROPERTY_ERROR = 'PROPERTY_ERROR'

export const USERS = 'USERS'

export const BLOGS = 'BLOGS'
export const BLOGS_LOADING = 'BLOGS_LOADING'
export const BLOGS_ERROR = 'BLOGS_ERROR'

export const PUBLIC_BLOGS = 'PUBLIC_BLOGS'
export const PUBLIC_BLOGS_LOADING = 'PUBLIC_BLOGS_LOADING'
export const PUBLIC_BLOGS_ERROR = 'PUBLIC_BLOGS_ERROR'

export const REQUESTEDBLOGS = 'REQUESTEDBLOGS'
export const REQUESTEDBLOGS_LOADING = 'REQUESTEDBLOGS_LOADING'
export const REQUESTEDBLOGS_ERROR = 'REQUESTEDBLOGS_ERROR'

export const LOAN_EMPLOYMENT_TYPE = 'LOAN_EMPLOYMENT_TYPE'

export const REFFERALS = 'REFFERALS'
export const TOP_BAR_LOADING = 'TOP_BAR_LOADING'

export const USERREWARD = 'USERREWARD'
export const USERREWARD_LOADING = 'USERREWARD_LOADING'
export const USERREWARD_ERROR = 'USERREWARD_ERROR'

export const USERSREWARD = 'USERSREWARD'
export const USERSREWARD_LOADING = 'USERSREWARD_LOADING'
export const USERSREWARD_ERROR = 'USERSREWARD_ERROR'
export const LOAN = 'LOAN'
export const LOAN_LOADING = 'LOAN_LOADING'
export const LOAN_ERROR = 'LOAN_ERROR'

export const REQUESTED_WITHDRAW = 'REQUESTED_WITHDRAW'
export const APPROVED_WITHDRAW = 'APPROVED_WITHDRAW'

export const FILTER_PROPERTY = 'FILTER_PROPERTY'
export const FILTER_PROPERTY_LOADING = 'FILTER_PROPERTY_LOADING'
export const FILTER_PROPERTY_ERROR = 'FILTER_PROPERTY_ERROR'

export const INSURANCEFORM = 'INSURANCEFORM'
export const INSURANCEFORM_LOADING = 'INSURANCEFORM_LOADING'
export const INSURANCEFORM_ERROR = 'INSURANCEFORM_ERROR'

export const COMMONFORM = 'COMMONFORM'
export const COMMONFORM_LOADING = 'COMMONFORM_LOADING'
export const COMMONFORM_ERROR = 'COMMONFORM_ERROR'

export const PROPERTYFORM = 'PROPERTYFORM'
export const PROPERTYFORM_LOADING = 'PROPERTYFORM_LOADING'
export const PROPERTYFORM_ERROR = 'PROPERTYFORM_ERROR'

export const LOANFORM = 'LOANFORM'
export const LOANFORM_LOADING = 'LOANFORM_LOADING'
export const LOANFORM_ERROR = 'LOANFORM_ERROR'

export const LOANPOSTS = 'LOANPOSTS'
export const LOANPOSTS_LOADING = 'LOANPOSTS_LOADING'
export const LOANPOSTS_ERROR = 'LOANPOSTS_ERROR'

export const INSTALLMENT_POSTS = 'INSTALLMENT_POSTS'
export const INSTALLMENT_POSTS_LOADING = 'INSTALLMENT_POSTS_LOADING'
export const INSTALLMENT_POSTS_ERROR = 'INSTALLMENT_POSTS_ERROR'
export const PUBLIC_INSTALLMENT_POSTS = 'PUBLIC_INSTALLMENT_POSTS'
export const PUBLIC_INSTALLMENT_POSTS_LOADING = 'PUBLIC_INSTALLMENT_POSTS_LOADING'
export const PUBLIC_INSTALLMENT_POSTS_ERROR = 'PUBLIC_INSTALLMENT_POSTS_ERROR'

export const PUBLIC_LOANPOSTS = 'PUBLIC_LOANPOSTS'
export const PUBLIC_LOANPOSTS_LOADING = 'PUBLIC_LOANPOSTS_LOADING'
export const PUBLIC_LOANPOSTS_ERROR = 'PUBLIC_LOANPOSTS_ERROR'
