import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPublicInstallmentPosts } from 'src/store/action'

import { proCard } from 'src/utils/constants'

const InstallmentCard = () => {
  const [loading, setLoading] = useState(false)
  const { publicInstallmentPost, publicInstallmentPostLoading } = useSelector(
    (state) => state.common,
  )
  console.log('publicInstallmentPost', publicInstallmentPost)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllPublicInstallmentPosts({}))
  }, [])
  const handleNavigation = () => {}
  return (
    <div className=" d-flex flex-column  flex-grow-1 mb-4 d-none d-md-flex">
      <div className="mb-5">
        <h2
          className="text-center"
          style={{
            fontfamily: 'Poppins',
            fontsize: '36px',
            fontweight: '500',
            color: '#1E1E1E',
          }}
        >
          AVAILABLE PLANS
        </h2>
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className="row col-12 align-items-center justify-content-center w-100 gap-3 ">
          {publicInstallmentPostLoading ? (
            <div className="d-flex justify-content-center">
              <Spinner />
            </div>
          ) : publicInstallmentPost && publicInstallmentPost.length > 0 ? (
            publicInstallmentPost.map((insp) => {
              return (
                <div key={insp?._id} className="col-2 border rounded-lg py-2 shadow-sm ">
                  <img
                    src={insp?.productImages[0]}
                    className="card-img-top border-bottom"
                    alt="Card Image"
                    style={{ maxHeight: '200px', objectFit: 'contain' }}
                  />
                  <div className="card-body">
                    <h6
                      className="card-title"
                      style={{
                        fontfamily: 'Poppins',
                        fontsize: '15px',
                        fontweight: '400',
                        color: '#4D4D4F',
                      }}
                    >
                      {insp?.productName}
                    </h6>
                    <p
                      className="card-text small"
                      style={{
                        fontfamily: 'Raleway',
                        fontsize: '14px',
                        fontweight: '500',
                        color: '#54595F',
                      }}
                    >
                      <span
                        style={{
                          fontfamily: 'Poppins',
                          fontsize: '15px',
                          fontweight: '400',
                          color: '#4D4D4F',
                        }}
                      >
                        {insp?.companyName}
                      </span>

                      <br />
                      <img
                        src={process.env.PUBLIC_URL + '/Header Icons/Symbol.png'}
                        className="mr-2"
                        alt="Icon"
                      />
                      {insp?.city}
                    </p>

                    <div className="row">
                      <div className="col-6 d-flex align-items-center">
                        <p className="" style={{ fontSize: '13px' }}>
                          Price:
                          <span>
                            <h6 style={{ color: '#B7242A' }}>{insp?.price}</h6>
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="" style={{ fontSize: '13px' }}>
                          Down Payment:
                          <span>
                            <h6 style={{ color: '#B7242A' }}>{insp?.downpayment}</h6>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 d-flex align-items-center">
                        <p className="" style={{ fontSize: '13px' }}>
                          Installment:
                          <span>
                            <h6 style={{ color: '#B7242A' }}>{insp?.installment}</h6>
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p className="" style={{ fontSize: '13px' }}>
                          Tenure:
                          <span>
                            <h6 style={{ color: '#B7242A' }}>{insp?.tenure}</h6>
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-end flex-wrap">
                      <button
                        type="button"
                        className="btn"
                        style={{ borderColor: '#B7242A', color: '#B7242A' }}
                        onClick={() => handleNavigation(proCard)}
                      >
                        <span className="small">Details</span>
                      </button>
                    </div> */}
                  </div>
                </div>
              )
            })
          ) : (
            <div className="d-flex justify-content-center">No record found</div>
          )}
        </div>
      )}
    </div>
  )
}

export default InstallmentCard
