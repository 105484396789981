import 'bootstrap/dist/css/bootstrap.min.css'
import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Toaster } from 'react-hot-toast'
import PublicBlogDetail from './views/pages/Clientside/PublicBlogDetailScreen'
import Installments from './views/pages/Clientside/Installments'
import CommonForm from './views/pages/loan/CommonForm'
import PropertyForm from './views/pages/loan/PropertyForm'
import ForgotPassword from './views/pages/resetaccount/ForgotPassword'
import OtpCode from './views/pages/resetaccount/Otp'
import ResetPassword from './views/pages/resetaccount/ResetPassword'
import VerifyUser from './views/pages/resetaccount/VerifyUser'
import { register } from './serviceWorker.js'

function Loading() {
  const [loadingProgress, setLoadingProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((prevProgress) => (prevProgress < 100 ? prevProgress + 1 : 100))
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="position-fixed w-100 top-0 start-0 zindex-5">
      {loadingProgress !== 100 && (
        <div style={{ width: `${loadingProgress}%`, height: '3px', background: 'red' }} />
      )}
    </div>
  )
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Homescreen = React.lazy(() => import('./views/pages/Clientside/Homescreen'))
const AllBlogs = React.lazy(() => import('./views/pages/Clientside/AllBlogs'))
const Aboutscreen = React.lazy(() => import('./views/pages/Clientside/Aboutscreen'))
const Servicescreen = React.lazy(() => import('./views/pages/Clientside/Servicescreen'))
const Insurancescreen = React.lazy(() => import('./views/pages/Clientside/Insurancescreen'))
const Blogscreen = React.lazy(() => import('./views/pages/Clientside/Blogscreen'))
const Properties = React.lazy(() => import('./views/pages/Clientside/Properties'))
const VisitProperty = React.lazy(() => import('./views/pages/Clientside/VisitProperty'))
const PropertiesDetail = React.lazy(() => import('./views/pages/Clientside/PropertiesDetail'))
const Loanscreen = React.lazy(() => import('./views/pages/Clientside/Loanscreen'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const LoanForm = React.lazy(() => import('./views/pages/loan/LoanForm'))
const InsuranceForm = React.lazy(() => import('./views/pages/loan/InsuranceForm'))
const Salaried = React.lazy(() => import('./views/pages/loan/Salaried'))
const Business = React.lazy(() => import('./views/pages/loan/Business'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  return (
    <HashRouter>
      <Toaster />
      <Loading />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path="/homescreen" name="Homescreen" element={<Homescreen />} />
          <Route exact path="/allblogs" name="AllBlogs" element={<AllBlogs />} />
          <Route exact path="/aboutscreen" name="Aboutscreen" element={<Aboutscreen />} />
          <Route exact path="/servicescreen" name="Servicescreen" element={<Servicescreen />} />
          <Route
            exact
            path="/insurancescreen"
            name="Insurancescreen"
            element={<Insurancescreen />}
          />
          <Route exact path="/installment" name="Installment" element={<Installments />} />
          <Route exact path="/blogscreen" name="Blogscreen" element={<Blogscreen />} />
          <Route
            exact
            path="/blogscreen/blogdetail"
            name="Blogscreen"
            element={<PublicBlogDetail />}
          />
          <Route exact path="/properties" name="Properties" element={<Properties />} />
          <Route
            exact
            path="/propertiesdetail"
            name="PropertiesDetail"
            element={<PropertiesDetail />}
          />
          <Route exact path="/visitproperty" name="VisitProperty" element={<VisitProperty />} />
          <Route exact path="/loanscreen" name="Loanscreen" element={<Loanscreen />} />
          <Route exact path="/business" name="Business" element={<Business />} />
          <Route exact path="/salaried" name="salaried" element={<Salaried />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/forgotpassword" name="Forgot password" element={<ForgotPassword />} />
          <Route exact path="/resetpassword" name="Reset password" element={<ResetPassword />} />
          <Route exact path="/otpverification" name="Otp Verification" element={<OtpCode />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/verifyuser" name="Verify User" element={<VerifyUser />} />
          <Route exact path="/loanform" name="Loan Form" element={<LoanForm />} />
          <Route exact path="/insuranceform" name="Insurance Form" element={<InsuranceForm />} />
          <Route exact path="/commonform" name="Common Form" element={<CommonForm />} />
          <Route exact path="/propertyform" name="Property Form" element={<PropertyForm />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}
register()
export default App
